/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* PRODUCT DETAILS */
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

export const GET_PRODUCT_COMMENTS = "GET_PRODUCT_COMMENTS"
export const GET_PRODUCT_COMMENTS_SUCCESS = "GET_PRODUCT_COMMENTS_SUCCESS"
export const GET_PRODUCT_COMMENTS_FAIL = "GET_PRODUCT_COMMENTS_FAIL"

export const ON_LIKE_COMMENT = "ON_LIKE_COMMENT"
export const ON_LIKE_COMMENT_SUCCESS = "ON_LIKE_COMMENT_SUCCESS"
export const ON_LIKE_COMMENT_FAIL = "ON_LIKE_COMMENT_FAIL"

export const ON_LIKE_REPLY = "ON_LIKE_REPLY"
export const ON_LIKE_REPLY_SUCCESS = "ON_LIKE_REPLY_SUCCESS"
export const ON_LIKE_REPLY_FAIL = "ON_LIKE_REPLY_FAIL"

export const ON_ADD_REPLY = "ON_ADD_REPLY"
export const ON_ADD_REPLY_SUCCESS = "ON_ADD_REPLY_SUCCESS"
export const ON_ADD_REPLY_FAIL = "ON_ADD_REPLY_FAIL"

export const ON_ADD_COMMENT = "ON_ADD_COMMENT"
export const ON_ADD_COMMENT_SUCCESS = "ON_ADD_COMMENT_SUCCESS"
export const ON_ADD_COMMENT_FAIL = "ON_ADD_COMMENT_FAIL"

/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

/**
 * add ORDER
 */
export const ADD_NEW_ORDER = "ADD_NEW_ORDER"
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS"
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

/**
 * Delete ORDER
 */
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"

/* CART DATA */
// export const GET_CART_DATA = "GET_CART_DATA"
// export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS"
// export const GET_CART_DATA_FAIL = "GET_CART_DATA_FAIL"

/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

/**
 * add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

/* SHOPS */
export const GET_SHOPS = "GET_SHOPS"
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS"
export const GET_SHOPS_FAIL = "GET_SHOPS_FAIL"
